import { Component, ViewChild } from '@angular/core';
import { QuillViewComponent } from "ngx-quill";
import { CalendarEntry } from '../../domain/calendar-entry';

@Component({
  selector: 'bridge-termine',
  templateUrl: './termine.component.html',
  styleUrls: ['./termine.component.scss'],
})
export class TermineComponent {

  entries: CalendarEntry[] = [
    {
      title: 'Mitgliederversammlung',
      description: '',
      time: '19.03.2025, 17:00 Uhr',
      deleteAt: new Date(2025, 3, 20).getTime(),
      location: "Clubräumlichkeiten",
    },
    {
      title: 'Kneipenturnier Thomas-á-Kempis',
      description: '',
      time: '27.04.2025, 09:30 Uhr',
      deleteAt: new Date(2025, 4, 28).getTime(),
      location: "Kolpinghaus",
    },
    {
      title: 'Sommerturnier',
      description: '',
      time: '27.07.2025, 14:00 Uhr',
      deleteAt: new Date(2025, 7, 28).getTime(),
      location: "Kaiserhof Willich",
    },
    {
      title: 'Jubiläumsturnier',
      description: '',
      time: '31.08.2025, 14:00 Uhr',
      deleteAt: new Date(2025, 9, 1).getTime(),
      location: "Clubräumlichkeiten",
    },
    {
      title: 'Tag der offenen Tür',
      description: '',
      time: '21.09.2025, 14:00 Uhr',
      deleteAt: new Date(2025, 9, 22).getTime(),
      location: "Clubräumlichkeiten",
    },
    {
      title: 'Weihnachtsturnier',
      description: '',
      time: '10.12.2025, 14:00 Uhr',
      deleteAt: new Date(2025, 12, 11).getTime(),
      location: "Lokalität wird noch bekannt gegeben",
    },
  ].filter(entry => entry.deleteAt >= new Date().getTime());
  @ViewChild('view', { static: false }) view: QuillViewComponent;

  getLines(text: string): string[] {
    return text && text.split('\n');
  }
}
